@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-face: satoshi;
}

.hero {
  background: linear-gradient(70deg, rgba(0, 0, 0, 0.8)0.51%, rgb(247, 137, 52, 1)100%);
}

.nav-fixed {
  position: fixed !important;
  top: 0px !important;
  z-index: 500;
}



svg.lof {
  stroke: #ffffff;
  font-size: 14rem;
  font-weight: 900;
  stroke-width: 5;
  animation: textAnimate 9s infinite alternate
}

svg.lof.section {
  stroke: #ffffff;
  font-size: 12rem;
}

svg.lof.section.about {
  stroke: #F78934;
}

.scroll {
  transition: all 300ms ease-in-out;
  animation: scrollAnimate 3s infinite alternate;
}

/* carousel */

.owl-theme .owl-dots {
  margin-top: 2rem !important;
}

.owl-carousel.owl-theme .owl-nav {
  display: block !important;
  margin-top: 15px;
}



.owl-carousel.owl-theme .owl-nav button span {
  font-size: 24px;
}

.owl-carousel.owl-theme .owl-nav button {
  margin: 0 1.5rem;
}

.owl-carousel.owl-theme .owl-nav button:first-child {
  margin-left: 0;
}

.owl-carousel.owl-theme .owl-nav button:last-child {
  margin-right: 0;
}

.owl-carousel.owl-theme .owl-nav button:hover,
.owl-carousel.owl-theme .owl-nav button:active,
.owl-carousel.owl-theme .owl-nav button:focus,
.owl-carousel.owl-theme .owl-dots button:hover,
.owl-carousel.owl-theme .owl-dots button:active,
.owl-carousel.owl-theme .owl-dots button:focus {
  background-color: transparent;
  box-shadow: none;
  outline: none;
  border: none;
}

.owl-carousel.owl-theme .owl-nav button span {
  color: white;
}

.owl-carousel.owl-theme .owl-nav button:hover span {
  color: #E54600;
}

.owl-nav button.owl-next {
  position: absolute;
  right: 20px;
  top: 35%;
}

.owl-nav button.owl-prev {
  position: absolute;
  left: 20px;
  top: 35%;

}

.owl-nav button.owl-next span,
.owl-nav button.owl-prev span {
  font-size: 5rem !important;
}

.owl-carousel.owl-theme.cup.prod-slider.owl-loaded.owl-drag  .owl-dots,
.owl-carousel.owl-theme.legends.prod-slider.owl-loaded.owl-drag  .owl-dots
 {
  display: none !important;
}

@keyframes scollAnimate {
  from {
    transform: translateY(-15px);
  }

  to {
    transform: translateY(15px);
  }
}


@keyframes textAnimate {
  from {
    stroke-dasharray: 0 50%;
    stroke-dashoffset: 20%;
  }

  to {
    stroke-dasharray: 50% 0;
    stroke-dashoffset: -20%;
  }
}

@media(max-width: 576px) {
  .scroll {
    left: 45% !important
  }

  svg.lof.section {
    stroke: #ffffff;
    font-size: 14rem !important;
  }

  .owl-theme .owl-dots {
    margin-top: 4rem !important;
  }

}

[data-aos-delay] {
  transition-delay: 0s !important;
}

/* navigation */
.header {
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  transition: all 0.15s ease;
  padding-block: 1rem;
}

.header.stick {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 1000;
}

.header.blocked {
  background-color: rgba(35, 52, 89, 0.91);
  /* box-shadow: 0 8px 40px 0 rgba(96, 108, 115, 0.1); */
}

.header.blocked.flat {
  background-color: #081C21;
}

.header.header-flat .logo-icon {
  width: 40px;
}

